<template>
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="60%"
    >

    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="状态">
              <el-select v-model="queryParam.reviewStatus" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="发起人">
              <el-select v-model="queryParam.promoterName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in promoterNameList"
                  :key="item.memberSign"
                  :label="item.userName"
                  :value="item.memberSign"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-button @click="selectByIdButton()">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <el-button @click="getDataButton">确定获取</el-button>
      <el-table
        :data="projectMember"
        @selection-change="handleSelectionChange"
        tooltip-effect="dark"
        ref="multipleTable"
        show-summary
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-popover placement="top" width="160" v-model="visible">
              <p>流程将关闭</p>
              <div style="text-align: right; margin: 10px 0px">
                <el-button size="mini" type="text" @click="visible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="closeButton(scope.row)"
                  >确定</el-button
                >
              </div>
              
            </el-popover> -->

            <el-button slot="reference" type="text"     @click="closeButton(scope.row)">关闭</el-button>
          </template>
        </el-table-column>
        <el-table-column label="流程状态" prop="reviewStatus" align="center" :formatter="reviewStatusFormat"/>
        <el-table-column label="流程编码" prop="code" align="center" />
        <el-table-column label="发起人" prop="promoterName" align="center" />
        <el-table-column label="金额" prop="realityMoney" align="center" />
        <el-table-column label="开始时间" prop="startTime" align="center" />
        <el-table-column label="结束时间" prop="endTime" align="center" />
        <el-table-column label="创建时间" prop="createdTime" align="center" />
      </el-table>
    </el-dialog>
  </div>
</template>
  <script>
import { downLoad } from "@/utils/zipdownload";
import {
  endListApi,
  processReviewCloseApi,
} from "@/api/work/project/reimburse";
import { getProjectPersonApi } from "@/api/work/project/project";
export default {
  data() {
    return {
      options:[{
        label:'确认',
        value:5
      },
      {
        label:'流程中',
        value:2
      },
      {
        label:'再次提交',
        value:6
      },
      {
        label:'全部',
        value:"999"
      }],
      promoterNameList:[],
      visible: false,
      queryParam:{
        promoterName:null
      },
      sizeForm: {},
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      projectMember: [],
      addEditForm: {},
      userList: [],
      userGetList: [],
      timer: null,
      list: [],
      ids: null,
      multipleSelection: [],
      types: 1,
      projectCode: null,
      reviewStatus:"999"
    };
  },
  mounted() {},
  methods: {
    initPerson(row) {
      getProjectPersonApi({ projectCode: row.code }).then((res) => {
        this.promoterNameList = res.result;
      });
    },
    show(row, type) {
      this.types = type;
      this.dialog = true;
      this.projectCode = row.code;
      this.projectMemberReqVOList = [];
      this.selectByIdButton(row.code);
      this.initPerson(row)
      this.editShow = true;
      this.title = "查看";
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$parent.id = null;
      this.projectCode = null;
      this.$parent.questionnaireId = null;
      this.projectMemberReqVOList = [];
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    //查询详情
    selectByIdButton() {
      this.reviewStatus=this.queryParam.reviewStatus==null?'999':this.queryParam.reviewStatus
      endListApi({ projectCode: this.projectCode,reviewStatus:this.reviewStatus }).then((res) => {
        this.projectMember = res.result;
        console.log(this.queryParam.promoterName)
        if(null!=this.queryParam.promoterName&&undefined!=this.queryParam.promoterName&&""!=this.queryParam.promoterName){
          this.projectMember = this.projectMember.filter(item=>item.reimburseBy===this.queryParam.promoterName);
        }

      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getDataButton() {
      if (this.multipleSelection.length > 0) {
        // 选择了数据
        let data = this.multipleSelection.map((obj) => obj.code).join(",");
        console.log(data);
        this.downloadResWordButton(data);
      }
    },
    closeButton(row) {
      console.log("-------");
      processReviewCloseApi({ code: row.code }).then((res) => {
        this.selectByIdButton();
      });
    },
    downloadResWordButton(row) {
      downLoad(
        "/xun/work/projectReimburse/downloadResWord?reimburseCodes=" + row,
        "xun"
      );
    },
    reviewStatusFormat(row, column) {
      if (row.reviewStatus == 1) {
        return "草稿";
      }
      if (row.reviewStatus == 2||row.reviewStatus == 6) {
        return "流程中";
      }
      if (row.reviewStatus == 3) {
        return "退回";
      }
      if (row.reviewStatus == 4) {
        return "拒绝";
      }
      if (row.reviewStatus == 5) {
        return "以接受";
      }
      if (row.reviewStatus == 7) {
        return "流程结束";
      }
    },
  },
};
</script>
  <style scoped>
</style>
  
  
  
  
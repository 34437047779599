import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/projectReimburse'

const adminListReimburseApi =(params)=>getAction(baseURL+"/adminList",params);
const listreimburseApi =(params)=>getAction(baseURL+"/list",params);

const addreimburseApi=(params)=>postAction(baseURL+"/add",params);

const editreimburseApi=(params)=>putAction(baseURL+"/edit",params);

const deletereimburseByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectreimburseByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const bathAddListApi=(params)=>postAction(baseURL+"/bathAddList",params);


const selectByIdInfoApi=(params)=>getAction(baseURL+'/selectByIdInfo',params);


const submitReimburseApi=(params)=>getAction(baseURL+"/submit",params);

const processReviewListApi=(params)=>getAction(baseURL+"/processReviewList",params);


const processReviewStatusApi=(params)=>getAction(baseURL+"/processReviewStatus",params);

const processReviewCloseApi=(params)=>getAction(baseURL+"/close",params);


const pushDataTaskApi = (params) => postAction(baseURL + "/pushDataTask", params);

const endListApi=(params)=>getAction(baseURL+"/endList",params);
export{
    pushDataTaskApi,
    endListApi,
    processReviewCloseApi,
    processReviewStatusApi,
    adminListReimburseApi,
    processReviewListApi,
    selectByIdInfoApi,
    selectreimburseByIdApi,
    deletereimburseByIdApi,
    editreimburseApi,
    addreimburseApi,
    listreimburseApi,
    bathAddListApi,
    submitReimburseApi
}

import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunProject'


const listprojectApi =(params)=>getAction(baseURL+"/list",params);

const addprojectApi=(params)=>postAction(baseURL+"/add",params);

const editprojectApi=(params)=>putAction(baseURL+"/edit",params);

const deleteprojectByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectprojectByIdApi=(params)=>getAction(baseURL+'/selectByCode',params);

const getProjectByUseSignApi=(params)=>getAction(baseURL+'/getProjectByUseSign',params);

const saveOrUpdateApi=(params)=>postAction(baseURL+'/saveOrUpdate',params);

const getProjectPersonApi=(params)=>getAction(baseURL+'/getProjectPerson',params);

export{
    getProjectPersonApi,
    selectprojectByIdApi,
    deleteprojectByIdApi,
    editprojectApi,
    addprojectApi,
    listprojectApi,
    saveOrUpdateApi,
    getProjectByUseSignApi
}
